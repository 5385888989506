<template>
  <vx-card title="Contenido">
    <p>Aquí se puede modificar la información más relevante de la página.</p>

    <div v-if="initProgress" style="margin-top: 1rem; width: 100%">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>
    <div class="mt-5">
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input @blur="content.title= trimString(content.title)" v-validate="'required'" name="title"
                    class="w-full" label="Titulo"
                    v-model="content.title"/>
          <span class="text-danger text-sm"
                v-show="errors.has('title')">{{ errors.first('title') }}</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-textarea @blur="content.description= trimString(content.description)" v-validate="'required'"
                       name="description" label="Descripción"
                       v-model="content.description"/>
          <span class="text-danger text-sm"
                v-show="errors.has('description')">{{ errors.first('description') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-1/2">
          <vs-input @blur="content.facebook= trimString(content.facebook)" v-validate="'required'"
                    name="facebook" class="w-full" label="Facebook"
                    v-model="content.facebook"/>
          <span class="text-danger text-sm"
                v-show="errors.has('facebook')">{{ errors.first('facebook') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <vs-input @blur="content.instagram= trimString(content.instagram)" v-validate="'required'"
                    name="instagram" class="w-full" label="Instagram"
                    v-model="content.instagram"/>
          <span class="text-danger text-sm"
                v-show="errors.has('instagram')">{{ errors.first('instagram') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input @blur="content.schedule= trimString(content.schedule)" v-validate="'required'"
                    name="schedule" class="w-full" label="Horario"
                    v-model="content.schedule"/>
          <span class="text-danger text-sm"
                v-show="errors.has('schedule')">{{ errors.first('schedule') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-1/2">
          <vs-input @blur="content.phone= trimString(content.phone)" v-validate="'required'"
                    name="phone" class="w-full" label="Telefono"
                    v-model="content.phone"/>
          <span class="text-danger text-sm"
                v-show="errors.has('phone')">{{ errors.first('phone') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <vs-input @blur="content.whatsApp= trimString(content.whatsApp)" v-validate="'required'"
                    name="whatsApp" class="w-full" label="WhatsApp"
                    v-model="content.whatsApp"/>
          <span class="text-danger text-sm"
                v-show="errors.has('whatsApp')">{{ errors.first('whatsApp') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-1/3">
          <vs-input @blur="content.email= trimString(content.email)" v-validate="'required'"
                    name="email" class="w-full" label="Email"
                    v-model="content.email"/>
          <span class="text-danger text-sm"
                v-show="errors.has('email')">{{ errors.first('email') }}</span>
        </div>
        <div class="vx-col w-1/3">
          <vs-input v-validate="'required'"
                    name="minimumOrder" class="w-full" label="Pedido mínimo"
                    v-model.number="content.minimumOrder"/>
          <span class="text-danger text-sm"
                v-show="errors.has('minimumOrder')">{{ errors.first('minimumOrder') }}</span>
        </div>
        <div class="vx-col w-1/3">
          <vs-input v-validate="'required'"
                    name="minimumDeliveryFree" class="w-full" label="Pedido mínimo para delivery gratis"
                    v-model.number="content.minimumDeliveryFree"/>
          <span class="text-danger text-sm"
                v-show="errors.has('minimumDeliveryFree')">{{ errors.first('minimumDeliveryFree') }}</span>
        </div>
      </div>

      <!--Instagram posts-->
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-textarea rows="10" @blur="content.instagramPosts= trimString(content.instagramPosts)"
                       v-validate="'required'"
                       name="instagramPosts" label="Links de posts de instagram"
                       v-model="content.instagramPosts"/>
          <span class="text-danger text-sm"
                v-show="errors.has('instagramPosts')">{{ errors.first('instagramPosts') }}</span>
        </div>
      </div>

      <!--Instagram token-->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input @blur="content.token= trimString(content.token)" v-validate="'required'" name="title"
                    class="w-full" label="Instagram token"
                    v-model="content.token"/>
          <span class="text-danger text-sm"
                v-show="errors.has('token')">{{ errors.first('token') }}</span>
        </div>
      </div>

      <div class="flex mt-6 flex-wrap items-center" slot="footer">
        <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
        <div v-if="progress" style="margin-top: 1rem; width: 100%">
          <vs-progress indeterminate color="primary"></vs-progress>
        </div>
      </div>

    </div>
  </vx-card>
</template>

<script>
import trimString from "../mixins/trimString";

const firebase = require("firebase")
// Required for side-effects
require("firebase/firestore")
let db = firebase.firestore()

export default {
  name: "Content",
  mixins: [
    trimString
  ],
  data() {
    return {
      content: {},
      progress: false,
      initProgress: false
    }
  },
  async created() {
    try {
      this.initProgress = true
      await db.collection("content").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.content = {
            id: doc.id,
            ...doc.data()
          }
        })
        this.initProgress = false
      })
    } catch (e) {
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  computed: {
    isFormValid() {
      return !this.errors.any()
    }
  },
  methods: {
    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.progress = true

          // Content
          let contentRef = db.collection('content').doc(this.content.id)

          //Clone to remove the id
          let cloneContent = Object.assign({}, this.content)
          delete cloneContent.id

          contentRef.update({
            ...cloneContent,
            uid: firebase.auth().currentUser.uid,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
          }).then(() => {
            this.progress = false
            this.$vs.notify({
              color: 'success',
              title: 'Contenido',
              text: 'Contenido modificado correctamente.'
            })
          }).catch(() => {
            this.progress = false
            this.$vs.notify({
              color: 'warning',
              title: '¡Oh no!',
              text: 'Hubo un error.'
            })
          })
        }
      })
    }
  }
}
</script>
